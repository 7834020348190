.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: #282c34;
  padding: 20px;
  border-radius: 4px;
}

.popup-heading {
  color: #ffbfa1;
}

.popup-button {
  display: inline-block;
  min-width: 70%;
  max-width: 70%;
  border: 2px solid aliceblue;
  font-size: 1.3em;
}

.popup-button:hover {
  background-color: #ffbfa1;
  color: #282c34;
  border-color: #ffbfa1;
}
